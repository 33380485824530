import React from 'react'
import { Link } from 'react-router-dom'
import { HiMiniArrowLongRight } from "react-icons/hi2";

const blog1 = `${process.env.PUBLIC_URL}/images/blog1.png`
const blog2 = `${process.env.PUBLIC_URL}/images/blog2.png`
const blog3 = `${process.env.PUBLIC_URL}/images/blog3.png`

const blog = [
    {
        id: 1,
        img: blog1,
        title: 'The real estate industry, a cornerstone of global economies',
        desc:'It is undergoing a significant transformation with the advent of blockchain technology.',
        time: '2 min read',
        views: '1209 views',
        date: '01 aug 2024',
    },
    {
        id: 2,
        img: blog2,
        title: 'High-value collectibles and vintage assets',
        desc:'the intersection of blockchain technology and real-world asset (RWA) tokenization',
        time: '5 min read',
        views: '1580 views',
        date: '22 july 2024',
    },
    {
        id: 3,
        img: blog3,
        title: 'Financial landscape Undergoing a Profound Transformation',
        desc:'driven by the advent of blockchain technology and the innovative practice of',
        time: '3 min read',
        views: '812 views',
        date: '25 july 2024',
    },
]

const Card = ({data}) => {
    return(
        <div className=' w-[357px] h-[420px] flex flex-col justify-between rounded-xl bg-white shadow-lg'>

             <img src={data.img} alt='blogs' className=' w-[357px] h-[240px] rounded-lg ' />

             <div className=' px-5 pt-2 flex w-full justify-between'>
                <span className=' pr-3 text-xs font-semibold border-r border-black'>{data.time}</span>
                <span className=' pr-8 text-xs font-semibold border-r border-black'>{data.views}</span>
                <span className=' pr-3 text-xs font-semibold'>{data.date}</span>
             </div>

            <div className=' pt-5 px-5'>
                <p className=' text-base text-neutral-700 font-bold'>{data.title}</p>
                <p className=' text-sm mt-3 text-neutral-700 font-semibold'>{data.desc}</p>
            </div>
            <Link  to={`/blogs/${data.id}`}><h1 className='flex gap-3 items-end pb-3 ml-5 mt-2 text-primary-1 font-semibold text-base'>Read Blog <span className=' hover:translate-x-1 transition-all text-xl'><HiMiniArrowLongRight /></span></h1></Link>

        </div>
    )
}

function Blogs() {
  return (
    <section className=' w-full flex flex-col py-10 md:px-20 px-5 bg-[#04a287] items-center '>
        <div className=' flex mt-5 w-full justify-center items-center gap-5 z-10'>
            <div className=' bg-white w-52 h-1'></div>
            <h1 className=' text-center lg:text-4xl text-2xl font-bold text-white'>Blogs</h1>
            <div className=' bg-white w-52 h-1'></div>
        </div>

        <Link to='/blogs' className='self-end'> <button className=' mt-10 px-4 py-2 bg-white text-primary-1 font-semibold rounded-md self-end'> Explore More</button> </Link>

        <div className=' mt-10 flex flex-wrap w-full gap-5 justify-center md:justify-between'>
            {blog.map((d,i) => (
                <Card data={d} />
            ))}
        </div>
    </section>
  )
}

export default Blogs;